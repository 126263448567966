<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Product Info Overview" />
      </v-col>
    </v-row>
    <mex-sperm-spinner
      v-if="isLoading"
      :spinnerText="loadingText"
    />
    <v-row v-else justify="center">
      <v-col cols="12">
        <v-tabs
          v-model="currentTab"
          background-color="foreground"
          color="primaryAccent"
          class="mt-5"
          grow
        >
          <v-tab>
            <v-row justify="center">
              <v-col align-self="center" cols="auto">Expired</v-col>
            </v-row>
          </v-tab>
          <v-tab>
            <v-row justify="center">
              <v-col align-self="center" cols="auto">Active</v-col>
            </v-row>
          </v-tab>
          <v-tab>
            <v-row justify="center">
              <v-col align-self="center" cols="auto">Planned</v-col>
            </v-row>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab" style="background-color: var(--v-foreground-base)">
          <v-tab-item class="pa-5">
            <product-info-overview-table :product-info-data="expiredProductInfos"></product-info-overview-table>
          </v-tab-item>
          <v-tab-item class="pa-5">
            <product-info-overview-table :product-info-data="activeProductInfos"></product-info-overview-table>
          </v-tab-item>
          <v-tab-item class="pa-5">
            <product-info-overview-table :product-info-data="plannedProductInfos"></product-info-overview-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import productInfosService from "../../services/productInfos.service";
import ImageUpload from "../../components/LicSrvComponents/ImageUpload.vue";
import ProductInfo from "../../components/LicSrvComponents/ProductInfo.vue";
import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable.vue";
import getTablePagination from "../../config/tablePagination.config";
import ProductInfoOverviewTable from "../../components/LicSrvComponents/ProductInfoOverviewTable.vue";
import { checkIfProductInfoIsPlanned, checkIfProductInfoIsExpired } from "../../functions/checkProductInfoStatus";

export default {
  name: "ProductInfoOverview",
  components: { MexDataTable, ProductInfo, ImageUpload, ProductInfoOverviewTable },
  computed: {
    getTablePagination() {
      return getTablePagination
    },
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getSelectedDrugName() {
      let drugNameEntry = this.drugNames.filter(x => x.value === this.selectedDrug);
      if(drugNameEntry?.length) {
        return drugNameEntry[0].text
      }
      return ""
    }
  },
  data() {
    return {
      currentTab: 1,
      hasPermission: false,
      isLoading: false,
      loadingText: "",
      productInfos: [],
      expiredProductInfos: [],
      activeProductInfos: [],
      plannedProductInfos: [],
    };
  },
  async created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.keyTEXAll, this.$store)
      .then(async (hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          await this.fetchProductInfos();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch((err) => {
        this.$router.push({ name: "NotFound" });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    fetchProductInfos() {
      this.isLoading = true;
      this.loadingText = "Fetching Product Infos";
      productInfosService.fetchProductInfos()
        .then((response) => {
          response.data.forEach((productInfo) => {
            const status = checkIfProductInfoIsPlanned(productInfo) ? "planned" : (checkIfProductInfoIsExpired(productInfo) ? "expired" : "active");
            this[`${status}ProductInfos`].push({
              productInfoID: productInfo.ProductInfoID,
              productInfoUUID: productInfo.productInfoUUID,
              drugID: productInfo.Drug.DrugID,
              drugName: `${productInfo.Drug.product} | MED_ID ${productInfo.Drug.medID}`,
              hyperlink: {
                name: productInfo.hyperlinkName,
                link: productInfo.hyperlink
              },
              validFrom: productInfo.validFrom,
              validTo: productInfo.validTo,
              status: status
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("sysAlert/addAlert", {
            type: "error",
            message: "Error fetching product infos",
          });
        });
    },
  }
};
</script>

<style></style>
