var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mex-data-table',{attrs:{"dense":"","data":_vm.productInfoData,"headers":_vm.productInfoTableHeaders,"hide-default-footer":true,"srvSideSizeItems":_vm.getTablePagination.rowsPerPageWithNames,"item-key":"ClinicID","tableClass":"foreground"},scopedSlots:_vm._u([{key:"item.drugName",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToDrugProductInfo(item.drugID, item.productInfoID)}}},[_vm._v(_vm._s(item.drugName))])]}},{key:"item.hyperlink",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-link")]),_c('span',{staticClass:"body-1"},[_vm._v(_vm._s(item.hyperlink.name))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.hyperlink.link))])])]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$dateFormatter.convertJsonDate(item.validFrom).date))])]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$dateFormatter.convertJsonDate(item.validTo).date))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'expired')?_c('mex-btn',{attrs:{"tooltip":"Product-Info is Expired","icon":"mdi-close","icon-only":"","color":"red"}}):(item.status === 'planned')?_c('mex-btn',{attrs:{"tooltip":"Product-Info is scheduled in the Future","icon":"mdi-clock","icon-only":"","color":"blue"}}):_c('mex-btn',{attrs:{"tooltip":"Product-Info is currently Active","icon":"mdi-check","icon-only":"","color":"green"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }