<template>
  <mex-data-table
    dense
    :data="productInfoData"
    :headers="productInfoTableHeaders"
    :hide-default-footer="true"
    :srvSideSizeItems="getTablePagination.rowsPerPageWithNames"
    item-key="ClinicID"
    tableClass="foreground"
  >
    <template v-slot:item.drugName="{ item }">
      <v-btn style="font-size: smaller; height: fit-content" class="pa-1" color="foreground" small @click="goToDrugProductInfo(item.drugID, item.productInfoID)">{{ item.drugName }}</v-btn>
    </template>
    <template v-slot:item.hyperlink="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                style="cursor: pointer;"
              >
                <v-icon color="primary" class="mr-2">mdi-link</v-icon>
                <span class="body-1">{{ item.hyperlink.name }}</span>
              </span>
        </template>
        <span>{{ item.hyperlink.link }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.validFrom="{ item }">
      <span>{{ $dateFormatter.convertJsonDate(item.validFrom).date }}</span>
    </template>
    <template v-slot:item.validTo="{ item }">
      <span>{{ $dateFormatter.convertJsonDate(item.validTo).date }}</span>
    </template>
    <template v-slot:item.status="{ item }">
      <mex-btn
        v-if="item.status === 'expired'"
        tooltip="Product-Info is Expired"
        icon="mdi-close"
        icon-only
        color="red"
      />
      <mex-btn
        v-else-if="item.status === 'planned'"
        tooltip="Product-Info is scheduled in the Future"
        icon="mdi-clock"
        icon-only
        color="blue"
      />
      <mex-btn
        v-else
        tooltip="Product-Info is currently Active"
        icon="mdi-check"
        icon-only
        color="green"
      />
    </template>
  </mex-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import tablePagination from '../../config/tablePagination.config';
import MexDataTable from "../MedITEX_Vue_Components/MexComponents/MexDataTable.vue";
import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton.vue";

export default {
  name: 'ProductInfoOverviewTable',
  components: { MexBtn, MexDataTable },
  props: {
    hideDrugProductInfoGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    productInfoData: {
      type: Array,
      default: () => [],
      description: 'The product-info with the properties drugName, hyperlink, validFrom, validTo, and drugID',
    },
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      productInfoTableHeaders: [
        {
          text: "Drug",
          value: "drugName",
          align: "start",
          sortable: true
        },
        {
          text: "Hyperlink",
          value: "hyperlink",
          align: "start",
          sortable: true
        },
        {
          text: "Valid From",
          value: "validFrom",
          align: "start",
          sortable: true
        },
        {
          text: "Valid To",
          value: "validTo",
          align: "start",
          sortable: true
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: false
        }
      ],
    };
  },
  methods: {
    goToDrugProductInfo(drugID, productInfoID) {
      this.$router.push({ name: "ProductInfoView", query: {
          id: drugID,
          selectedProductInfoID: productInfoID
        }
      });
    },
  }
};
</script>

<style> </style>
